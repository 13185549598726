import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { TextField, Button, Typography, Paper } from '@material-ui/core';
import FileBase from 'react-file-base64';
import { useDispatch, useSelector } from 'react-redux';
import { createPost, updatePost } from '../../actions/posts';

//get the current ID we are on which is the ... sign on the post

const Form = ({currentId, setCurrentId}) =>{
    const [postData, setPostData] = useState({
        creator: '',
        title:'',
        message:'',
        tags:'',
        selectedFile:''
    });

    const post = useSelector((state) => currentId? state.posts.find((p) => p._id === currentId) : null);
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(()=>{
        if(post) setPostData(post);
    },[post]);

    const handleSubmit = (e) => {
        //this line of code make sure the value does not gets refresh value in the browser
        e.preventDefault();
        if(currentId){
            dispatch(updatePost(currentId, postData));
        }else{
            if(postData.creator && postData.title !== ""){
            dispatch(createPost(postData));
            }else{
            alert("给我填完这个便条贴它！！!");    
            }
        }
        clear();
    }

    const clear = () => {
        setCurrentId(null);
        setPostData({
        creator: '',
        title:'',
        message:'',
        tags:'',
        selectedFile:''
        });
    }

    return(
        <Paper className={classes.paper}>
            <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
            <Typography variant="h6"> {currentId? '修改': '创建'}爱的便利贴</Typography>
            <TextField 
            name="creator" 
            variant="outlined" 
            label="爱的留言者" 
            fullWidth 
            value={postData.creator} 
            onChange={(e)=> setPostData({...postData, creator: e.target.value })} 
            />
            <TextField 
            name="title" 
            variant="outlined" 
            label="便利贴题目" 
            fullWidth 
            value={postData.title} 
            onChange={(e)=> setPostData({...postData, title: e.target.value })} 
            />
            <TextField 
            name="message" 
            variant="outlined" 
            label="便利贴" 
            fullWidth 
            value={postData.message} 
            onChange={(e)=> setPostData({...postData, message: e.target.value })} 
            />
            <TextField 
            name="tags" 
            variant="outlined" 
            label="标记" 
            fullWidth 
            value={postData.tags} 
            onChange={(e)=> setPostData({...postData, tags: e.target.value })} 
            />
            <div className={classes.fileInput}>
                <FileBase type="file" multiple={false} onDone={({base64}) => setPostData({ ...postData, selectedFile: base64 })}  />
            </div>
            <Button className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit" fullWidth>{currentId? '修改' : '上传'}</Button>
            <Button variant="contained" color="secondary" size="small" onClick={clear} fullWidth>一键清除</Button>
            </form>
        </Paper>
    );
}

export default Form;
